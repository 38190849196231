







































































import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";
import {
  SearchType,
  ParticipantDataType,
  ParticipantRegistrationType,
  ParticipantAbstractType,
  ParticipantMessageType,
  ParticipantPaymentsType,
  ParticipantOtherType,
} from "@/types/participantSearch";

export default defineComponent({
  components: {
    MParticipantData: () =>
      import(
        "@/components/molecules/participant/advancedSearch/m-participant-data.vue"
      ),
    MParticipantGroup: () =>
      import(
        "@/components/molecules/participant/advancedSearch/m-participant-group.vue"
      ),
    MParticipantRegistration: () =>
      import(
        "@/components/molecules/participant/advancedSearch/m-participant-registration.vue"
      ),
    MParticipantAccommodation: () =>
      import(
        "@/components/molecules/participant/advancedSearch/m-participant-accommodation.vue"
      ),
    MParticipantServices: () =>
      import(
        "@/components/molecules/participant/advancedSearch/m-participant-services.vue"
      ),
    MParticipantAbstract: () =>
      import(
        "@/components/molecules/participant/advancedSearch/m-participant-abstract.vue"
      ),
    MParticipantSurveys: () =>
      import(
        "@/components/molecules/participant/advancedSearch/m-participant-surveys.vue"
      ),
    MParticipantMessage: () =>
      import(
        "@/components/molecules/participant/advancedSearch/m-participant-message.vue"
      ),
    MParticipantPayments: () =>
      import(
        "@/components/molecules/participant/advancedSearch/m-participant-payments.vue"
      ),
    MOtherCriteria: () =>
      import(
        "@/components/molecules/participant/advancedSearch/m-other-criteria.vue"
      ),
    MParticipantConsents: () =>
      import(
        "@/components/molecules/participant/advancedSearch/m-participant-consents.vue"
      ),
    MSearchResults: () =>
      import(
        "@/components/molecules/participant/advancedSearch/m-search-results.vue"
      ),
  },
  setup(_, { root }) {
    const form = ref<any>(null);

    const model: any = reactive({
      participantData: {
        participantState: {
          entity: "participant",
          fieldName: "state",
          values: [],
          operation: "in",
        },
        participantType: {
          entity: "participant",
          fieldName: "type",
          values: [],
          operation: "like",
        },
        participantCode: {
          entity: "participant",
          fieldName: "code",
          values: "",
          operation: "like",
        },
        participantGender: {
          entity: "participant",
          fieldName: "gender",
          values: "",
          operation: "like",
        },
        participantFirstName: {
          entity: "participant",
          fieldName: "firstName",
          values: "",
          operation: "like",
        },
        participantLastName: {
          entity: "participant",
          fieldName: "lastName",
          values: "",
          operation: "like",
        },
        participantDegree: {
          entity: "participant",
          fieldName: "degree",
          values: [],
          operation: "in",
        },
        participantEmail: {
          entity: "participant",
          fieldName: "email",
          values: "",
          operation: "like",
        },
        participantPhone: {
          entity: "participant",
          fieldName: "phoneNumber",
          values: "",
          operation: "like",
        },
        participantInstitution: {
          entity: "participant",
          fieldName: "institutionName",
          values: "",
          operation: "like",
        },
        participantCity: {
          entity: "participant",
          fieldName: "addressCity",
          values: "",
          operation: "like",
        },
        participantCountry: {
          entity: "participant",
          fieldName: "country",
          values: [],
          operation: "in",
        },
        participantSponsor: {
          entity: "participant",
          fieldName: "sponsor",
          values: "",
          operation: "like",
        },
        participantNote: {
          entity: "participant",
          fieldName: "note",
          values: "",
          operation: "like",
        },
      } as ParticipantDataType,
      group: {
        entity: "participantGroup",
        fieldName: "id",
        values: "",
        operation: "or",
      },
      participantRegistration: {
        registrationType: {
          entity: "registrationFee",
          fieldName: "id",
          values: [],
          operation: "in",
        },
        registrationDate: {
          entity: "participant",
          fieldName: "createdAt",
          values: [],
          operation: "between",
        },
        registrationWithCompanion: {
          entity: "participant",
          fieldName: "companion",
          values: "",
          operation: "isNotNull",
        },
      } as ParticipantRegistrationType,
      participantAccommodation: {
        entity: "accommodation.room",
        fieldName: "id",
        values: [],
        operation: "in",
      },
      additionalServices: {
        entity: "participantAdditionalService.service",
        fieldName: "id",
        values: [],
        operation: "in",
      },
      participantAbstract: {
        abstractTitle: {
          entity: "abstract",
          fieldName: "title",
          values: "",
          operation: "like",
        },
        abstractType: {
          entity: "abstract",
          fieldName: "type",
          values: [],
          operation: "in",
        },
      },
      participantSurvey: {
        entity: "survey",
        fieldName: "id",
        values: [],
        operation: "in",
      },
      participantMessage: {
        message: {
          entity: "message",
          fieldName: "id",
          values: [],
          operation: "in",
        },
        messageDate: {
          entity: "mailTasks",
          fieldName: "createdAt",
          values: [],
          operation: "between",
        },
      },
      participantPayments: {
        paymentStatus: {
          entity: "participant",
          fieldName: "paymentStatus",
          values: [],
          operation: "in",
        },
        invoiceStatus: {
          entity: "participant",
          fieldName: "invoiceNumber",
          values: "",
          operation: "like",
        },
        invoicePurchaser: {
          entity: "participant",
          fieldName: "invoicePurchaser",
          values: "",
          operation: "like",
        },
        invoiceNumber: {
          entity: "participant",
          fieldName: "invoiceNumber",
          values: "",
          operation: "like",
        },
        invoiceName: {
          entity: "participant",
          fieldName: "invoiceName",
          values: "",
          operation: "like",
        },
        paymentDate: {
          entity: "payment",
          fieldName: "paymentDate",
          values: [],
          operation: "between",
        },
        // paymentName: {
        //   entity: "participant",
        //   fieldName: "createdAt",
        //   values: "",
        //   operation: "like",
        // },
      },
      participantConsents: {
        marketingConsent: {
          entity: "participant",
          fieldName: "marketingConsent",
          values: "",
          operation: "is",
        },
        emailOffersConsent: {
          entity: "participant",
          fieldName: "emailOffersConsent",
          values: "",
          operation: "is",
        },
        phoneOffersConsent: {
          entity: "participant",
          fieldName: "phoneOffersConsent",
          values: "",
          operation: "is",
        },
        termsConsent: {
          entity: "participant",
          fieldName: "termsConsent",
          values: "",
          operation: "is",
        },
      },
      otherCriteria: {
        emailExist: {
          entity: "participant",
          fieldName: "email",
          values: "",
          operation: "isNull",
        },
        emailUnique: {
          entity: "participant",
          fieldName: "email",
          values: "",
          operation: "isUnique",
        },
        participantClone: {
          entity: "participant",
          fieldName: "clone",
          values: "",
          operation: "like",
        },
        participantWasPresent: {
          entity: "participant",
          fieldName: "present",
          values: "",
          operation: "is",
        },
        participantCertificate: {
          entity: "participant",
          fieldName: "receiveCertificateDate",
          values: "",
          operation: "isNull",
        },
        participantSurvey: {
          entity: "surveyList",
          fieldName: "id",
          values: "",
          operation: "isNull",
        },
        participantRegistrationFeeTermDiscount: {
          entity: "participantRegistrationFeeTerm",
          fieldName: "discountCode",
          values: "",
          operation: "isNotNull",
        },
        participantAccommodationDiscount: {
          entity: "accommodation",
          fieldName: "discountCode",
          values: "",
          operation: "isNotNull",
        },
        participantServicesDiscount: {
          entity: "participantAdditionalService",
          fieldName: "discountCode",
          values: "",
          operation: "isNotNull",
        },
        participantNote: {
          entity: "participant",
          fieldName: "note",
          values: "",
          operation: "isNull",
        },
      },
    });

    const state = reactive({
      loading: false,
      degrees: [],
      error: false as boolean | number,
      expanded: 0,
      searchResults: [],
      searchCriteria: [] as SearchType[],
      dataSearch: [] as SearchType[],
      groupSearch: [] as SearchType[],
      registrationSearch: [] as SearchType[],
      accommodationSearch: [] as SearchType[],
      surveySearch: [] as SearchType[],
      servicesSearch: [] as SearchType[],
      abstractSearch: [] as SearchType[],
      messageSearch: [] as SearchType[],
      paymentSearch: [] as SearchType[],
      consentSearch: [] as SearchType[],
      otherSearch: [] as SearchType[],
      renderId: "",
      event: {},
      sponsors: [],
    });

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/basic`)
        .then(({ data: { event } }) => {
          state.event = event;
          state.degrees = event.degrees;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchEvent);

    const fetchSponsors = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/participant/sponsor`)
        .then(({ data }) => {
          state.sponsors = data.filter(
            (el: { sponsor: string }) => el.sponsor !== ""
          );
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchSponsors);

    watch(
      () => model.participantData,
      () => {
        const tempArray: SearchType[] = Object.keys(
          model.participantData as ParticipantDataType
        ).map((e) => model.participantData[e]);
        state.dataSearch = tempArray.filter(
          (el: SearchType) => el.values && el.values.length
        );
      },
      { deep: true }
    );
    watch(
      () => model.group,
      () => {
        const tempArray: SearchType[] = [model.group];
        state.groupSearch = tempArray.filter(
          (el: SearchType) => el.values && el.values.length
        );
      },
      { deep: true }
    );
    watch(
      () => model.participantRegistration,
      () => {
        const tempArray: SearchType[] = Object.keys(
          model.participantRegistration as ParticipantRegistrationType
        ).map((e) => model.participantRegistration[e]);
        state.registrationSearch = tempArray.filter(
          (el: SearchType) => el.values && el.values.length
        );
      },
      { deep: true }
    );
    watch(
      () => model.participantAccommodation,
      () => {
        const tempArray: SearchType[] = [model.participantAccommodation];
        state.accommodationSearch = tempArray.filter(
          (el: SearchType) => el.values && el.values.length
        );
      },
      { deep: true }
    );
    watch(
      () => model.participantSurvey,
      () => {
        const tempArray: SearchType[] = [model.participantSurvey];
        state.surveySearch = tempArray.filter(
          (el: SearchType) => el.values && el.values.length
        );
      },
      { deep: true }
    );
    watch(
      () => model.additionalServices,
      () => {
        const tempArray: SearchType[] = [model.additionalServices];
        state.servicesSearch = tempArray.filter(
          (el: SearchType) => el.values && el.values.length
        );
      },
      { deep: true }
    );
    watch(
      () => model.participantAbstract,
      () => {
        const tempArray: SearchType[] = Object.keys(
          model.participantAbstract as ParticipantAbstractType
        ).map((e) => model.participantAbstract[e]);
        state.abstractSearch = tempArray.filter(
          (el: SearchType) => el.values && el.values.length
        );
      },
      { deep: true }
    );
    watch(
      () => model.participantMessage,
      () => {
        const tempArray: SearchType[] = Object.keys(
          model.participantMessage as ParticipantMessageType
        ).map((e) => model.participantMessage[e]);
        state.messageSearch = tempArray.filter(
          (el: SearchType) => el.values && el.values.length
        );
      },
      { deep: true }
    );
    watch(
      () => model.participantPayments,
      () => {
        const tempArray: SearchType[] = Object.keys(
          model.participantPayments as ParticipantPaymentsType
        ).map((e) => model.participantPayments[e]);
        state.paymentSearch = tempArray.filter(
          (el: SearchType) => el.values && el.values.length
        );
      },
      { deep: true }
    );
    watch(
      () => model.participantConsents,
      () => {
        const tempArray: SearchType[] = Object.keys(
          model.participantConsents as ParticipantPaymentsType
        ).map((e) => model.participantConsents[e]);
        state.consentSearch = tempArray.filter((el: SearchType) => el.values);
      },
      { deep: true }
    );
    watch(
      () => model.otherCriteria,
      () => {
        const tempArray: SearchType[] = Object.keys(
          model.otherCriteria as ParticipantOtherType
        ).map((e) => model.otherCriteria[e]);
        state.otherSearch = tempArray.filter((el: SearchType) => el.values);
      },
      { deep: true }
    );

    watch(
      () => state.searchResults,
      () => {
        if (state.searchResults && state.searchResults.length > 0) {
          state.expanded = 1;
        }
      },
      { deep: true }
    );

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.participantNotFound")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        criteria: state.searchCriteria.concat(
          state.dataSearch,
          state.groupSearch,
          state.registrationSearch,
          state.accommodationSearch,
          state.surveySearch,
          state.servicesSearch,
          state.abstractSearch,
          state.messageSearch,
          state.paymentSearch,
          state.consentSearch,
          state.otherSearch
        ),
      };

      state.loading = true;

      axiosInstance
        .post(
          `event/${root.$route.params.id}/participant/advanced-search`,
          data,
          {
            headers: {
              event: root.$route.params.id,
            },
          }
        )
        .then(({ data }) => {
          state.searchResults = data;
          state.renderId = uuid();
          if (state.searchResults.length < 1) {
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: root.$tc("panel.event.participant.search.notFound"),
            });
          }
          state.error = false;
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    const summarySearch = () => {
      if (root.$route.params.fee) {
        state.dataSearch = [
          {
            entity: "participant",
            fieldName: "state",
            values: ["participant", "reserve"],
            operation: "in",
          },
          {
            entity: "registrationFee",
            fieldName: "id",
            values: [root.$route.params.fee],
            operation: "in",
          },
        ];
        onSubmit();
      } else if (root.$route.params.room) {
        state.dataSearch = [
          {
            entity: "participant",
            fieldName: "state",
            values: ["participant", "reserve"],
            operation: "in",
          },
          {
            entity: "accommodation.room",
            fieldName: "id",
            values: [root.$route.params.room],
            operation: "in",
          },
        ];
        onSubmit();
      } else if (root.$route.params.service) {
        state.dataSearch = [
          {
            entity: "participant",
            fieldName: "state",
            values: ["participant", "reserve"],
            operation: "in",
          },
          {
            entity: "participantAdditionalService.service",
            fieldName: "id",
            values: [root.$route.params.service],
            operation: "in",
          },
        ];
        onSubmit();
      } else if (root.$route.params.group) {
        state.dataSearch = [
          {
            entity: "participant",
            fieldName: "state",
            values: ["participant", "reserve"],
            operation: "in",
          },
          {
            entity: "participantGroup",
            fieldName: "id",
            values: [root.$route.params.group],
            operation: "in",
          },
        ];
        onSubmit();
      }
    };

    onMounted(summarySearch);

    const clearForm = () => {
      form.value.reset();
    };

    return {
      form,
      model,
      state,
      onSubmit,
      clearForm,
    };
  },
});
